<mat-expansion-panel class="widget mat-elevation-z" hideToggle [expanded]="expanded">
  <mat-expansion-panel-header class="widget__header">
    <mat-panel-title>
      <div class="expand-icon" [class._active]="false">
        <svg width="7" height="9" viewBox="0 0 7 9" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M7 4.5L0.250001 8.39712L0.250001 0.602886L7 4.5Z" fill="white" />
        </svg>
      </div>
      <div class="title">flight preferences</div>
    </mat-panel-title>
  </mat-expansion-panel-header>
  <div class="widget__body" *ngIf="frp && this.frp.preferences && isPreferencesNotEmpty; else empty">
    <div class="row" *ngIf="frp.preferences.aircraft">
      <div class="widget__field">
        <div class="title">Specific aircraft preference</div>
        <div class="value mb-10" *ngIf="frp.preferences.aircraft.option === 'includeAircrafts'">Need a specific aircraft type of class</div>
        <div class="value mb-10" *ngIf="frp.preferences.aircraft.option === 'excludeAircrafts'">DON'T LIKE the following aircraft</div>
        <div class="sub-value" *ngIf="frp.preferences.aircraft.comment">{{frp.preferences.aircraft.comment}}</div>
      </div>
    </div>
    <div class="row" *ngIf="frp.preferences.ageRestriction">
      <div class="widget__field">
        <div class="title">Aircraft age restriction</div>
        <div class="value" *ngIf="frp.preferences.ageRestriction.option === 'noMoreThan5or10Years'">No more than 5-10 years old max</div>
        <div class="value" *ngIf="frp.preferences.ageRestriction.option === 'noMoreThan20Years'">No more than 20 years old</div>
      </div>
    </div>
    <div class="row" *ngIf="frp.preferences.luggage">
      <div class="widget__field">
        <div class="title">Non-standard luggage</div>
        <div class="value">{{frp.preferences.luggage.comment}}</div>
      </div>
    </div>
    <div class="row" *ngIf="frp.preferences.flexibility">
      <div class="widget__field">
        <div class="title">Flexibility</div>
        <div class="value" *ngIf="frp.preferences.flexibility.option === 'timeFlexible'">Date isn`t, but departure time is flexible</div>
        <div class="value" *ngIf="frp.preferences.flexibility.option === 'dateAndTimeFlexible'">Date and departure time is flexible</div>
      </div>
    </div>
    <div class="row" *ngIf="frp.preferences.otherInfo && frp.preferences.otherInfo.comment">
      <div class="widget__field">
        <div class="title">Further relevant info</div>
        <div class="sub-value">{{frp.preferences.otherInfo.comment}}</div>
      </div>
    </div>
  </div>
  <ng-template #empty>
    <div class="d-f ai-c jc-c op-06 fs-18">No flight preferences</div>
  </ng-template>
</mat-expansion-panel>
