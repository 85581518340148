import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Component, OnInit, ElementRef, ViewChild, Inject, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, FormGroupDirective, ValidationErrors, Validators } from '@angular/forms';
import { BehaviorSubject, Subject, Subscription, timer } from 'rxjs';
import { debounce, debounceTime, first, map, takeUntil, tap } from 'rxjs/operators';
import { ChatMessage, ChatMessageData } from '../../../interfaces/ChatMessage';
import { ChatService } from '../../../services/api/chat.service';
import { UserService } from '../../../services/api/user.service';
import { User } from '../../../interfaces/User';

@Component({
  selector: 'lib-chat',
  templateUrl: './chat.component.html',
  styleUrls: [ './chat.component.scss' ],
})
export class ChatComponent implements OnInit, OnDestroy {
  @ViewChild('chatcontent') chatcontent!: ElementRef;
  chatForm!: UntypedFormGroup;
  chatId = '';
  curUser!: User;
  // matcher = new MyErrorStateMatcher();
  chatMessages$ = new BehaviorSubject<ChatMessageData[] | null>([]);
  chatMessagesSub: Subscription | null = null;
  loading = false;
  isTyping = false;
  unsubscribe$ = new Subject<void>;

  constructor(
    public dialogRef: MatDialogRef<ChatComponent>,
    @Inject(MAT_DIALOG_DATA) public offer: {
      offer: string;
      status: string;
      onlyRead: boolean;
      isClient: boolean;
    },
    private formBuilder: UntypedFormBuilder,
    private chatService: ChatService,
    private userService: UserService,
  ) { }

  ngOnInit(): void {
    this.getUserInfo();
    this.chatForm = this.formBuilder.group({
      message: [ null, [ Validators.required, this.validatorTrim ] ]
    });

    this.chatForm.get('message')?.valueChanges
      .pipe(
        tap(() => {
          if (!this.isTyping) {
            this.isTyping = true;
            this.chatService.setTyping(this.chatId, this.curUser.firebaseId, true);
          }
        }),
        debounceTime(5000),
        tap(() => {
          this.isTyping = false;
          this.chatService.setTyping(this.chatId, this.curUser.firebaseId, false);
        })
      )
      .subscribe();

    this.chatId = this.offer.offer;
    if (this.chatId) {
      this.chatService.createChat(this.chatId)
        .pipe(first())
        .subscribe(
          () => this.chatService.subscribeChat(this.chatId, this.updateMessagesList),
          console.log, // TODO message
        );
    } else {
      alert('No chat id.'); // TODO message
    }

    this.chatMessages$
      .pipe(
        takeUntil(this.unsubscribe$),
        map(results => results?.sort((a, b) => new Date(a.sentDate).getTime() - new Date(b.sentDate).getTime())),
        debounce(() => timer(500)),
        tap(() => {
          this.chatService.removeUnread(this.chatId, this.curUser.firebaseId);
          this.scrollToBottomOfChat();
        }),
      ).subscribe();
  }

  validatorTrim(formControl: AbstractControl): ValidationErrors | null {
    const isWhitespace = (formControl.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { whitespace: true };
  }

  getUserInfo(): void {
    this.userService.getUserInfo()
      .pipe(first())
      .subscribe(resp => {
        this.curUser = resp;
      });
  }

  onFormSubmit(formDirective: FormGroupDirective): void {
    if (this.chatForm.invalid) return;
    this.loading = true;
    this.chatService.sendMessage(
      this.chatId,
      {
        senderId: this.curUser.firebaseId,
        senderName: this.curUser.firstName && this.curUser.lastName ? `${this.curUser.firstName} ${this.curUser.lastName}` : this.curUser.email,
        sentDate: new Date().toISOString(),
        txt: this.chatForm.value.message?.trim()
      } as ChatMessageData
    )
      .then(resp => {
        this.loading = false;
        if (this.offer.isClient) {
          this.chatService.sendClientChatMessage(this.chatId, {
            text: this.chatForm.value.message.trim(),
            messageId: resp
          })
            .pipe(first())
            .subscribe(data => {
              console.log('Push notification sent', data);
            });
        } else {
          this.chatService.sendChatMessage(this.chatId, this.chatForm.value.message.trim())
            .pipe(first())
            .subscribe(data => {
              console.log('Push notification sent', data);
            });
        }
        formDirective.resetForm();
        this.chatForm.reset();
        this.isTyping = false;
        this.chatService.setTyping(this.chatId, this.curUser.firebaseId, false);
      })
      .catch(err => {
        this.loading = false;
        formDirective.resetForm();
        this.chatForm.reset();
        console.error(err);
      });
  }

  updateMessagesList = (message: ChatMessage): void => {
    this.chatService.removeUnread(this.chatId, this.curUser.firebaseId);
    if (this.chatMessages$.value) {
      this.chatMessages$.next(this.chatMessages$.value.concat(message.data));
    } else {
      this.chatMessages$.next([message.data]);
    }
  };

  onNoClick(): void {
    this.chatService.unSubscribeChat(this.chatId);
    this.dialogRef.close();
  }

  ngOnDestroy(): void {
    this.chatService.unSubscribeChat(this.chatId);
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  sameWithPreviousDate(i: number): boolean {
    if (i === 0 || !this.chatMessages$.value) return false;
    return this.chatMessages$.value[i - 1].sentDate.toString().split('T')[0] === this.chatMessages$.value[i].sentDate.toString().split('T')[0];
  }

  sameWithPreviousSender(i: number): boolean | string {
    if (i === 0 || !this.chatMessages$.value) return false;
    return this.chatMessages$.value[i - 1].senderId === this.chatMessages$.value[i].senderId && this.sameWithPreviousDate(i) ? true : false;
  }

  scrollToBottomOfChat(): void {
    if (this.chatcontent?.nativeElement) this.chatcontent.nativeElement.scrollTop = this.chatcontent.nativeElement.scrollHeight;
  }

  getInicials(name: string): string {
    const arr = name.split(' ');
    if (arr[1]) {
      return arr[0][0]+arr[1][0];
    } else {
      return arr[0][0]+arr[0][1];
    }
  }

}
