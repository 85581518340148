<div class="modal-top row jc-sb ai-c">
  <div class="modal-top__title">Chat</div>
  <button class="modal-top__btn" mat-icon-button (click)="onNoClick()">
    <img src="assets/img/cancel.svg" alt="">
  </button>
</div>
<ng-container *ngIf="(chatMessages$ | async) as chatMessages">
  <div *ngIf="chatMessages.length; else chat;" class="chat" #chatcontent mat-dialog-content>
    <div class="chat-row" *ngFor="let message of chatMessages; index as i">
      <div class="chat-day" *ngIf="!sameWithPreviousDate(i)">
        {{message.sentDate | date: 'MMM, d'}}
      </div>
      <div class="row-body" [class.new-sender]="message.senderId === curUser?.firebaseId">
        <div class="row-header">
          <div class="header-icon" *ngIf="!sameWithPreviousSender(i)">
            <div *ngIf="message.senderId === curUser?.firebaseId || !offer.isClient; else manager">
              {{getInicials(message.senderName)}}
            </div>
            <ng-template #manager>
              <mat-icon>person</mat-icon>
            </ng-template>
          </div>
        </div>
        <div class="row-content">
          <div class="row-name" *ngIf="!sameWithPreviousSender(i)">
            {{message.senderId === curUser?.firebaseId || !offer.isClient ? message.senderName : 'OPES JET Travel Manager'}}
          </div>
          <div class="row-text" [innerText]="message.txt"></div>
        </div>
        <div class="row-date">{{message.sentDate | date: 'HH:mm'}}</div>
      </div>
    </div>
  </div>
  <ng-template #chat>
    <div class="no-chat" mat-dialog-content>No messages yet</div>
  </ng-template>
  <div *ngIf="!offer.onlyRead" class="message" mat-dialog-actions>
    <form class="message-form" [formGroup]="chatForm" (ngSubmit)="onFormSubmit(formDirective)" #formDirective="ngForm">
      <mat-form-field class="message-form-field">
        <textarea (keydown.enter)="$event.preventDefault(); onFormSubmit(formDirective)" matInput placeholder="Enter message here" formControlName="message" cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="1" cdkAutosizeMaxRows="6" class="message-textarea"></textarea>
        <button type="submit" [disabled]="!chatForm.valid || loading" matSuffix mat-icon-button aria-label="Send">
          <mat-icon>send</mat-icon>
        </button>
      </mat-form-field>
      <div *ngIf="!offer.isClient && offer.status !== 'accepted'" class="px-12 fs-12 op-06">Please do not mention your company name and contact information until the offer is booked. This contradicts OPES JET Terms and Conditions and may cause your account suspended</div>
    </form>
  </div>
</ng-container>
