import { Component, Input } from '@angular/core';
import { FRP } from '../../../interfaces/FRP';

@Component({
  selector: 'lib-flight-preferences',
  templateUrl: './flight-preferences.component.html'
})
export class FlightPreferencesComponent {
  @Input() frp?: FRP;
  @Input() expanded!: boolean;

  get isPreferencesNotEmpty(): number {
    return this.frp?.preferences ? Object.keys(this.frp.preferences as object).length : 0;
  }

}
