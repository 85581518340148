import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { first } from 'rxjs';
import { BookingsService } from '../../../services/api/bookings.service';
import { ChecklistComponent } from '../../modals/checklist/checklist.component';

@Component({
  selector: 'lib-booking-checklist',
  templateUrl: './booking-checklist.component.html',
  styleUrls: ['./booking-checklist.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BookingChecklistComponent {

  @Input() bookingId?: string;
  @Input() items?: { name: string, visible: boolean, isChecked: boolean }[];

  constructor(
    private dialog: MatDialog,
    private bookingsService: BookingsService,
    private cd: ChangeDetectorRef
  ) { }

  createChecklistFromDefault(): void {
    if (this.bookingId) this.bookingsService.createChecklistFromDefault(this.bookingId).pipe(first()).subscribe(() => {
      this.bookingsService.getBookings().pipe(first()).subscribe();
    });
  }

  updateChecklist(): void {
    if (this.bookingId && this.items) this.bookingsService.createChecklistFromScratch(this.bookingId, this.items).pipe(first()).subscribe();
  }

  openChecklist(): void {
    const dialogRef = this.dialog.open(ChecklistComponent, {
      width: '486px',
      data: this.items,
      panelClass: 'no-padding-dialog',
      autoFocus: false
    });
    dialogRef.afterClosed().pipe(first()).subscribe(data => {
      if(data) {
        this.items = data;
        this.updateChecklist();
        this.cd.detectChanges();
      }
    })
  }

  filterItems(items: { name: string, visible: boolean, isChecked: boolean }[]): { name: string, visible: boolean, isChecked: boolean }[] {
    return items?.filter(item => item.visible) || [];
  }

}
